import React from 'react';
import {graphql} from "gatsby";
import Layout from "../components/Layout";

export default function FaqPage(props) {
  const {location, data: {markdownRemark: {frontmatter: {questions}}}} = props;
  const reg = new RegExp("/en");
  const en = reg.test(location.pathname);
  return (
      <Layout location={location}>
        <section className="section">
          <div className="container columns">
            <div className="column is-10 is-offset-1">
              <h1 className={'title is-size-1'}>
                Frequently Asked Questions
              </h1>
            </div>
          </div>
        </section>

        {questions.map(({question, question_en, answer, answer_en}, i) =>
            <section className="section" key={i} style={{paddingTop: i !== 0 ? 0 : '3rem'}}>
              <div className="container columns">
                <div className="column is-10 is-offset-1">
                  <h4 className="title is-size-4 has-text-weight-bold is-bold-light">
                    {en ? question_en : question}
                  </h4>
                  <h5 className="title is-size-5 has-text-grey">
                    {en ? answer_en : answer}
                  </h5>
                  {(i !== questions.length -1) && <hr style={{marginTop:'3rem'}}/>}
                </div>
              </div>
            </section>
        )}
      </Layout>
  )
}

export const pageQuery = graphql`
    query FaqPage {
        markdownRemark(frontmatter: { templateKey: { eq: "faq-page" } }) {
            frontmatter {
                questions {
                    question
                    question_en
                    answer
                    answer_en
                }
            }
        }
    }
`
